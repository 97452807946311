window.Popper = require('popper.js');
window.$ = window.jQuery = require('jquery');

import Swiper from 'swiper';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css/swiper.css';

function navScroll() {
    var scrollPos = $(window).scrollTop();
    if (scrollPos > 30) {
        $('.header').addClass('sticky');
        $('.index').removeClass('scroll');
    } else {
        $('.header').removeClass('sticky');
        $('.index').addClass('scroll');
    }
}

$(window).scroll(function () {
    navScroll();
});

$(document).ready(function () {
    navScroll();
});

AOS.init();

$(document).ready(function () {

    var fullWidth = new Swiper('#fullWidth', {
        pagination: {
            el: '.swiper-pagination',
        },
    });
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });

    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        pagination: {
            el: '.swiper-pagination',
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });

    $(".mobile_head > span").click(function () {
        if ($('.mobile_body').is(':visible')) {
            $(".mobile_body").slideUp(300);
            $(this).text('+');
        }
        if ($(this).parent().next(".mobile_body").is(':visible')) {
            $(this).parent().next(".mobile_body").slideUp(300);
            $(this).text('+');
        } else {
            $(this).parent().next(".mobile_body").slideDown(300);
            $(this).text('–');
        }
    });

    $("a").on('click', function(event){
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();
    
            var hash = this.hash;
    
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {
    
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });
        }
    });

    initForms();
    workoutVideos();

    function initForms() {
        const ajaxForms = $('.ajaxForm');
        ajaxForms.submit(function(ev) {
            const successMessage = $(this).find('.success');
            const failMessage = $(this).find('.fail');
            ev.preventDefault();
            $.post({
                url: '/',
                dataType: 'json',
                data: $(this).serialize(),
                success: function(response) {
                    if (response.success) {
                        successMessage.addClass('shown');
                    } else {
                        failMessage.addClass('shown');
                    }
                }
            });
        });
    }

    function workoutVideos(){
        $('iframe[src*=".com"]').wrap( "<div class='videoWrapper'></div>" );
    }
});

$(function () {
    $('.toggle-nav').click(function () {
        $('body').toggleClass('show-nav');
        navScroll();
        if ($("body").hasClass("show-nav")) {
            $('.index').removeClass('scroll');
        }
    });

    $(window).on('resize', function () {
        var win = $(this);
        if (win.width() >= 1180) {
            $('body').removeClass('show-nav');
        }
    });
});

$(document).ready(function () {

    var accordion_head = $('.accordion > li > a'),
        accordion_body = $('.accordion li > .sub-menu, .last-sub-menu');

    // Click function
    accordion_head.on('click', function (event) {

        // Disable header links
        event.preventDefault();

        // Show and hide the tabs on click
        if ($(this).attr('class') != 'active') {
            accordion_body.slideUp('normal');
            $(this).next().stop(true, true).slideToggle('normal');
            accordion_head.removeClass('active');
            $(this).addClass('active');
        } else {
            accordion_body.slideUp('normal');
            accordion_head.removeClass('active');
        }
    });

});

$(document).ready(function () {

    $(window).on('load', function () {
        if ($(this).width() >= 640) {
            $(".tab_content").hide();
            $(".tab_content:first").show();
        }

    });


    /* if in tab mode */
    $("ul.tabs li").on('click', function () {

        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();

        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");

        $(".tab_drawer_heading").removeClass("d_active");
        $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass("d_active");

    });

});
